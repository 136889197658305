// extracted by mini-css-extract-plugin
export var FeaturesList = "styles-module--FeaturesList--c8de5";
export var FeaturesListDash = "styles-module--FeaturesListDash--f4895";
export var FeaturesListImage = "styles-module--FeaturesListImage--fd20b";
export var FeaturesListImageIcons = "styles-module--FeaturesListImageIcons--170b5";
export var FeaturesListText = "styles-module--FeaturesListText--22bb9";
export var FeaturesListTitle = "styles-module--FeaturesListTitle--2eda2";
export var FeaturesLists = "styles-module--FeaturesLists--97158";
export var logisticsCover = "styles-module--logisticsCover--4c8b9";
export var logisticsDetails = "styles-module--logisticsDetails--42f6c";
export var logisticsDetailsSection = "styles-module--logisticsDetailsSection--3046f";
export var logisticsDetailsSectionImage = "styles-module--logisticsDetailsSectionImage--24b1b";
export var logisticsDetailsSectionText = "styles-module--logisticsDetailsSectionText--ab585";
export var logisticsFeatures = "styles-module--logisticsFeatures--f20b8";
export var logisticsFeaturesText = "styles-module--logisticsFeaturesText--8d245";
export var logisticsFeaturesTextHeading = "styles-module--logisticsFeaturesTextHeading--df0f8";
export var logisticsFeaturesTextHeadingPrimary = "styles-module--logisticsFeaturesTextHeadingPrimary--2a0ed";
export var logisticsFeaturesTextHeadingSecondary = "styles-module--logisticsFeaturesTextHeadingSecondary--1fab8";
export var logisticsFeaturesTextSub = "styles-module--logisticsFeaturesTextSub--e46a6";
export var logisticsHeader = "styles-module--logisticsHeader--1e826";
export var logisticsHeaderImage = "styles-module--logisticsHeaderImage--6f99d";
export var logisticsHeaderText = "styles-module--logisticsHeaderText--92a8c";
export var logisticsSection = "styles-module--logisticsSection--895d4";