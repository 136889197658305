import React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Logistics from "../../components/LogisticsPage/LogisticsPage"

const logistics = () => {
  return (
    <Layout>
      <Seo title="Logistics" />
      <Logistics />
    </Layout>
  )
}

export default logistics
