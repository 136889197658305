import React from "react"
import * as styles from "./styles.module.scss"
import Container from "../Container/Container"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

//SVG
import Delivery from "../../images/Logistics/shuttle.svg"
import Support from "../../images/Logistics/support.svg"
import Verified from "../../images/Logistics/verified.svg"
import Pricing from "../../images/Logistics/solution.svg"
import Dash from "../../images/Logistics/dash.svg"

const LogisticsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "Logistics/Header.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      bottom: file(relativePath: { eq: "Logistics/Bottom.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <section className={styles.logisticsSection}>
      <section className={styles.logisticsCover}>
        <Container>
          <section className={styles.logisticsHeader}>
            <div className={styles.logisticsHeaderText}>
              <h1>FAST, CERTIFIED & FLEXIBLE SOLUTIONS</h1>
              <p>
                Through integrated supply chain solutions, our drives
                sustainable competitive advantages to some of the lagest
                companies all over India.
              </p>
            </div>
            <div className={styles.logisticsHeaderImage}>
              <Img fluid={data.header.childImageSharp.fluid} />
            </div>
          </section>
        </Container>
      </section>
      <Container>
        <section className={styles.logisticsFeatures}>
          <div className={styles.logisticsFeaturesText}>
            <div className={styles.logisticsFeaturesTextHeading}>
              <h3 className={styles.logisticsFeaturesTextHeadingSecondary}>
                Real Solutions, Real Fast!
              </h3>
              <h1 className={styles.logisticsFeaturesTextHeadingPrimary}>
                Delivering The Best Global Logistics Solutions.
              </h1>
            </div>
            <div className={styles.logisticsFeaturesTextSub}>
              <p>
                Our global logistics expertise, advanced supply chain technology
                & customized logistics solutions will help you analyze, develop
                and implement successful supply chain management strategies.
              </p>
            </div>
          </div>
          <div className={styles.FeaturesLists}>
            <div className={styles.FeaturesList}>
              <div className={styles.FeaturesListImage}>
                <img
                  src={Delivery}
                  alt="Delivery"
                  className={styles.FeaturesListImageIcons}
                ></img>
              </div>
              <h1 className={styles.FeaturesListTitle}>Fast Delivery</h1>
              <img
                className={styles.FeaturesListDash}
                src={Dash}
                alt="dash"
              ></img>

              <p className={styles.FeaturesListText}>
                Cooperating with UK Traders you are guaranteed to have your
                cargo delivered fast and secure.
              </p>
            </div>
            <div className={styles.FeaturesList}>
              <div className={styles.FeaturesListImage}>
                <img
                  src={Support}
                  alt="Support"
                  className={styles.FeaturesListImageIcons}
                ></img>
              </div>
              <h1 className={styles.FeaturesListTitle}>24/7 Support</h1>
              <img
                className={styles.FeaturesListDash}
                src={Dash}
                alt="dash"
              ></img>

              <p className={styles.FeaturesListText}>
                We provide you with 24/7 support, which gives you an opportunity
                to track your cargo on its way.
              </p>
            </div>
            <div className={styles.FeaturesList}>
              <div className={styles.FeaturesListImage}>
                <img
                  src={Verified}
                  alt="Verified"
                  className={styles.FeaturesListImageIcons}
                ></img>
              </div>
              <h1 className={styles.FeaturesListTitle}>Secured Services</h1>
              <img
                className={styles.FeaturesListDash}
                src={Dash}
                alt="dash"
              ></img>

              <p className={styles.FeaturesListText}>
                We provide all our clients with professional transportation
                services on the highest security level.
              </p>
            </div>
            <div className={styles.FeaturesList}>
              <div className={styles.FeaturesListImage}>
                <img
                  src={Pricing}
                  alt="Pricing"
                  className={styles.FeaturesListImageIcons}
                ></img>
              </div>
              <h1 className={styles.FeaturesListTitle}>Affordable Pricing</h1>
              <img
                className={styles.FeaturesListDash}
                src={Dash}
                alt="dash"
              ></img>

              <p className={styles.FeaturesListText}>
                We have the lowest prices on the market and offer affordable
                solutions tailored to your business.
              </p>
            </div>
          </div>
        </section>
      </Container>
      <section className={styles.logisticsDetails}>
        <Container>
          <div className={styles.logisticsDetailsSection}>
            <div className={styles.logisticsDetailsSectionImage}>
              <Img fluid={data.bottom.childImageSharp.fluid} />
            </div>
            <div className={styles.logisticsDetailsSectionText}>
              <h1>Who we are</h1>
              <p>
                UK Traders is a nationalwide transport and logistics company and
                provides added-value services in the areas of road transport
                logistics and customs and forwarding services.
              </p>
              <p>
                As a fully integrated transport and logistics provider,
                TransLogic is able to make your logistics network more
                (cost-)efficient, whatever your business sector. Our goal is to
                develop customized, high-quality, large-scale logistical
                projects for our customers in order to provide a definite
                competitive advantage.{" "}
              </p>
            </div>
          </div>
        </Container>
      </section>
    </section>
  )
}

export default LogisticsPage
